import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'Fantasy'}
              title={'Fantastic Beasts and Where to Find Them'}
              image={'/books/fantasic_beasts_and_where_to_find_them.jpg'}
              alt={''}
            >
              <div className={styles.content}>
              <br></br>
              <h2>ENG</h2>
              <br></br>
              <p className={styles.excerpt}>

              "Fantastic Beasts and Where to Find Them" is a book written by J.K. Rowling, published in 2001, that serves as a companion guide to the Harry Potter series. The book is written as if it were a textbook from Hogwarts School of Witchcraft and Wizardry and contains information on magical creatures in the wizarding world.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              The book is divided into several sections, each focusing on a different type of magical creature. The first section is on the history of magic and magical creatures, explaining how they were discovered and how they interact with the wizarding world. The following sections focus on specific creatures, such as dragons, unicorns, and mermaids. Each creature is given a detailed description, including its appearance, habitat, behavior, and any known magical powers or abilities.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              In addition to the descriptions, the book includes drawings and illustrations of the creatures, as well as notes and annotations from Harry, Ron, and Hermione, who have borrowed the book from the Hogwarts library. These notes add a humorous and interactive element to the book, as the characters offer their own insights and opinions on the creatures.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              The book also includes a foreword by Hogwarts headmaster Albus Dumbledore, who explains the importance of understanding and respecting magical creatures. He stresses the need for empathy and compassion towards these creatures, and warns against mistreating or exploiting them for personal gain.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Throughout the book, Rowling weaves in references to the larger wizarding world, providing insights into the history and culture of the magical community. For example, she mentions the Ministry of Magic's classification system for magical creatures, which ranges from "X" (boring) to "XXXXX" (dangerous). She also describes the role of the Department for the Regulation and Control of Magical Creatures, which oversees the treatment of magical creatures and ensures they are not mistreated or abused.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              "Fantastic Beasts and Where to Find Them" is an enjoyable and informative read for fans of the Harry Potter series, as it expands on the rich and imaginative world created by Rowling. The book offers a unique glimpse into the diversity of creatures that inhabit the wizarding world, and provides readers with a greater understanding and appreciation for the magical creatures that exist in Rowling's universe.

              </p>
              <p className={styles.excerpt}>

              The book has since been adapted into a movie franchise, also written by Rowling, which follows the adventures of Newt Scamander, a magizoologist and author of "Fantastic Beasts and Where to Find Them," as he travels the world in search of magical creatures. The movies offer a new perspective on the creatures described in the book, bringing them to life on the big screen and introducing new characters and plotlines that expand on the wizarding world.
              
              </p>
              </div>
              <div className={styles.content}>
              <br></br>
              <h2>LV</h2>
              <br></br>
              <p className={styles.excerpt}>

              "Fantastiskie zvēri un kur tos atrast" ir grāmata, ko sarakstījis Dž.K. Roulinga, kas publicēta 2001. gadā un kalpo kā Harija Potera sērijas pavadošais ceļvedis. Grāmata ir uzrakstīta tā, it kā tā būtu Cūkkārpas raganu un burvju skolas mācību grāmata, un tajā ir informācija par burvju pasaules burvju radībām.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmata ir sadalīta vairākās sadaļās, katra koncentrējoties uz cita veida maģiskām radībām. Pirmā sadaļa ir par maģijas un maģisko radījumu vēsturi, paskaidrojot, kā tās tika atklātas un kā tās mijiedarbojas ar burvju pasauli. Nākamajās sadaļās galvenā uzmanība ir pievērsta konkrētām radībām, piemēram, pūķiem, vienradžiem un nārām. Katrai būtnei tiek sniegts detalizēts apraksts, ieskaitot tās izskatu, dzīvotni, uzvedību un visas zināmās maģiskās spējas vai spējas.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Papildus aprakstiem grāmatā ir iekļauti radījumu zīmējumi un ilustrācijas, kā arī Harija, Rona un Hermiones piezīmes un anotācijas, kuri grāmatu aizņēmušies no Cūkkārpas bibliotēkas. Šīs piezīmes pievieno grāmatai humoristisku un interaktīvu elementu, jo varoņi piedāvā savus ieskatus un viedokļus par radībām.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Grāmatā ir iekļauts arī Cūkkārpas direktora Albusa Dumbldora priekšvārds, kurā izskaidrots, cik svarīgi ir saprast un cienīt maģiskas radības. Viņš uzsver vajadzību pēc empātijas un līdzjūtības pret šiem radījumiem un brīdina par sliktu izturēšanos pret tiem vai to izmantošanu personīga labuma gūšanai.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Visā grāmatā Roulinga auž atsauces uz plašāku burvju pasauli, sniedzot ieskatu maģiskās kopienas vēsturē un kultūrā. Piemēram, viņa min Burvju ministrijas maģisko radījumu klasifikācijas sistēmu, kas svārstās no "X" (garlaicīgi) līdz "XXXXX" (bīstami). Viņa arī apraksta Maģisko radījumu regulēšanas un kontroles departamenta lomu, kas pārrauga izturēšanos pret maģiskām būtnēm un nodrošina, ka pret tām neizturas slikti vai ļaunprātīgi.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              "Fantastiskie zvēri un kur tos atrast" ir patīkama un informatīva lasāmviela Harija Potera sērijas cienītājiem, jo tā paplašina Roulingas radīto bagāto un iztēles pasauli. Grāmata piedāvā unikālu ieskatu radību daudzveidībā, kas apdzīvo burvju pasauli, un sniedz lasītājiem labāku izpratni un atzinību par maģiskajām radībām, kas pastāv Roulingas Visumā.

              </p>
              <p className={styles.excerpt}>

              Kopš tā laika grāmata ir pārveidota par filmu franšīzi, ko arī sarakstījusi Roulinga un kas seko burvju zinātnieka un grāmatas “Fantastiskie zvēri un kur tos atrast” autora Ņūta Skandera piedzīvojumiem, kad viņš ceļo pa pasauli, meklējot maģiskas radības. Filmas piedāvā jaunu skatījumu uz grāmatā aprakstītajām būtnēm, atdzīvinot tās uz lielā ekrāna un iepazīstinot ar jauniem varoņiem un sižetiem, kas izvēršas burvju pasaulē.
              
              </p>
              </div>
            </Blog>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
